/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import Container from "../components/layout/container";
import BottomSection from "../components/sections/bottomSection";
import TopSection from "../components/sections/topSection";
import payloadState from "../recoil/payloadState";
import image from "../assets/images/illustrations/person-celebrating.png";
import numeral from "numeral";
import SolidButton from "../components/buttons/solidButton";
import { prepareParameters } from "../util/helpers";
import LipadSpinner from "../components/animations/lipadSpinner";
import lipadCoin from "../assets/images/logos/lipad-logo-coin.svg";

export default function PaymentSuccessfulPage() {
  const payload = useRecoilValue(payloadState);
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          window.open(
            `${
              payload.merchant_site_data.success_redirect_url
            }?${prepareParameters({
              merchant_transaction_id:
                payload.merchant_site_data.merchant_transaction_id,
              checkout_request_id: payload.checkout_request_id,
              overall_payment_status: 801,
            })}`,
            "_self"
          );
          return 1;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [payload]);

  return (
    <Container>
      <div>
        <TopSection />
        <div className="w-[22rem] p-8 sm:w-96">
          <div className="mt-8 flex flex-col items-center gap-4 text-center">
            <div className="relative h-36 w-36">
              <img src={image} alt="" />
            </div>
            <h2 className="text-primary text-3xl font-semibold">
              Payment Successful
            </h2>
            <p className="text-lg">
              Your payment of{" "}
              {payload.merchant_site_data.running_amounts.origin_currency_code}{" "}
              {numeral(
                payload.merchant_site_data.running_amounts
                  .origin_total_payable_amount
              ).format("0,0.00")}{" "}
              to{" "}
              <span className="font-bold">
                {payload.merchant_site_data.client_service.client_service_name}
              </span>{" "}
              was successfully completed.
            </p>
            <p className="text-2xl font-bold">
              Transaction Reference{" "}
              {payload.merchant_site_data.merchant_transaction_id}
            </p>
          </div>
          <div className="mt-8 text-center">
            <p className="mb-2">You will be automatically redirected in</p>
            <div className="flex items-center justify-center gap-2">
              <div>
                <LipadSpinner size={76} image={lipadCoin} />
              </div>
              <span className="w-48 font-rubik text-5xl font-medium text-lipad-green">
                00:{String(seconds - 1).padStart(2, "0")}
              </span>
            </div>
          </div>
          <p className="mt-8 text-center text-lg text-gray-500">
            Or click here to redirect manually
          </p>
          <div className="mt-1">
            <SolidButton
              onClick={() =>
                window.open(
                  `${
                    payload.merchant_site_data.success_redirect_url
                  }?${prepareParameters({
                    merchant_transaction_id:
                      payload.merchant_site_data.merchant_transaction_id,
                    checkout_request_id: payload.checkout_request_id,
                    overall_payment_status: 801,
                  })}`,
                  "_self"
                )
              }
              label="Redirect"
            />
          </div>
        </div>
        <BottomSection />
      </div>
    </Container>
  );
}
