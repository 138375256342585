import gsap from "gsap";
import React, { useEffect, useRef } from "react";

const LipadSpinner = ({ size = 300, image }) => {
  const spinnerRef = useRef(null);

  useEffect(() => {
    const spinner = spinnerRef.current;

    gsap.to(spinner, {
      rotation: 360,
      duration: 2,
      repeat: -1,
      ease: "none",
      transformOrigin: "50% 50%",
    });

    return () => {
      gsap.killTweensOf(spinner);
    };
  }, []);

  return (
    <div className="relative flex h-full w-full items-center justify-center">
      <div className="relative" style={{ width: size, height: size }}>
        <svg
          ref={spinnerRef}
          viewBox="0 0 100 100"
          className="absolute left-0 top-0 h-full w-full"
        >
          <defs>
            <linearGradient id="spinnerGradient" gradientTransform="rotate(0)">
              <stop offset="0%" stopColor="#2D9B84" />
              <stop offset="50%" stopColor="#1F3487" />
              <stop offset="100%" stopColor="rgba(255,255,255,0)" />
            </linearGradient>
          </defs>
          <circle
            cx="50"
            cy="50"
            r="45"
            fill="none"
            stroke="url(#spinnerGradient)"
            strokeWidth="3"
            strokeLinecap="round"
            strokeDasharray="283"
            strokeDashoffset="0"
          />
        </svg>

        <div className="absolute left-1/2 top-1/2 h-3/4 w-3/4 -translate-x-1/2 -translate-y-1/2 transform">
          {image !== null && (
            <img
              src={image}
              alt="Logo"
              className="h-full w-full object-contain p-2"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LipadSpinner;
